import {required} from 'vuelidate/lib/validators'
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                show_order_statuses: [],
            },
            checkedAllStatuses: false,
        }
    },

    validations: {
        form: {
            title: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            department:'catalog/department',
            orderStatusList: 'orders/orderStatusList',
        }),
    },
    watch:{
        department(e){
            if(e){
                this.form.title = e.title;
                if(e.show_order_statuses.length){
                    this.form.show_order_statuses = e.show_order_statuses
                }
            }
        },
        orderStatusList(e) {
            if(!this.form.show_order_statuses.length){
                e.data.map(item=>{
                    this.form.show_order_statuses.push({
                        id: item.id,
                        title: item.title,
                        value: item.value,
                        color: item.color,
                    })
                })
            }


        }

    },
    created() {
        this.getOrderStatuses();
    },
    methods: {
        changeToggle() {
            const audio = new Audio(require('../../../../assets/sounds/BUTTON_SWITCH.mp3'))
            audio.play();
        },
        checkAllStatuses() {
            if (this.checkedAllStatuses) {
                this.form.show_order_statuses.map(item => {
                    item.value = true;
                })
            } else {
                this.form.show_order_statuses.map(item => {
                    item.value = false;
                })
            }
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendPriceCategory() {
            this.form.show_order_statuses.map(item=>{
                item.value = Number(item.value);
            })
            if(this.department){
                const payload = {
                    id: this.department.id,
                    data:this.form
                }
                this.$emit('changeDepartment', payload)
            }else{
                this.$emit('sendDepartment', this.form)
            }
        },
        ...mapActions({
            getOrderStatuses: 'orders/getOrderStatuses',
        }),
        ...mapMutations({
            changeDepartment: 'catalog/changeDepartment'
        }),
    },
    destroyed() {
        this.changeDepartment(null);
    }
}
